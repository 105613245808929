@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  max-height: 100vh;
}

html {
  overflow: hidden;
}

a {
  text-decoration: none;
}

.App {
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
}

.mainbox {
  display: flex;
  flex-direction: row;
  width: 55vw;
  height: 80vh;
}

@media screen and (max-width: 1200px) {
  .mainbox {
    display: flex;
    flex-direction: column;
    width: 100vw;
  }
  .contentbox {
    flex-grow: 1;
    margin-left: 0px !important;
  }
  .sidebarbox {
    min-width: 280px;
    margin-left: 40px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
  }
  .gorna-belka {
    margin-bottom: 3vh !important;
  }
  .sidebar-bottom {
    margin-top: 50px;
  }
}

.sidebarbox {
  min-width: 315px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.contentbox {
  flex-grow: 2;
  margin-left: 100px;
}

.przycisk {
  margin-top: 20px;
}

.menu-header {
  display: flex;
  align-items: center;
}

.menu-header h2 {
  margin-bottom: 0px;
  margin-top: 10px;
}

.menu-header h2:hover {
  color: orange;
}

.arrow-box {
  display: block;
  width: 30px;
  margin-left: -30px;
}

.arrow {
  margin-left: 0px;
  margin-right: 20px;
  font-size: 20px;
  display: none;
  transition: all 0.5s cubic-bezier(0,1,0,1);
}

.arrow.hover {
  display: block;
  margin-right: 20px;
  font-size: 20px;
  transition: all 0.5s cubic-bezier(1,0,1,0);
  
}

.arrow.active {
  display: block;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: all 0.5s cubic-bezier(0,1,0,1);
}

.menu-title {
  cursor: pointer;
}

.menu-content {
  max-height: 0px;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0,1,0,1);
}

.menu-content.show {
  max-height: 500px;
  transition: all 0.5s cubic-bezier(1,0,1,0);
}

#panel-boczny {
  margin-bottom: 30px;
}



@media screen and (max-height: 700px) {
  html {
    overflow: scroll;
  }
  body {
    height: auto;
    min-height: 95vh;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}

.gorna-belka {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 20px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 2vh;
  margin-bottom: 2vh;
  width: 50vw;
}

.belka-dol {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  font-size: 35px;
  font-weight: 800;
  padding-left: 35px;
  color: gray;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-style: normal;
}

.belka-dol ul {
  margin-top: 50px;
}

.belka-dol li {
  margin: 0 30px;
}

@media screen and (min-width: 671px) {
  .belka-dol {
    margin-bottom: 2vh;
    max-width: 75vw;
    width: 75vw;
  }
}

.content {
  margin-left: 13vw;
  margin-right: 10vw;
}

#logo {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1/2;
  display: inline-block;
}

#logo img {
  max-height: 65px;
  display: block;
}

#sociale {
  display: block;
  float: left;
  margin-left: 35px;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
  -ms-grid-row: 4;
  -ms-grid-row-span: 1;
  grid-row: 4/5;
}

#sociale #ikony {    
  display: flex;
  flex-direction: row;
  justify-items: initial;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
}

#sociale #ikony div {
  float: left;
  margin-right: 5px;
  margin-top: 15px;
}

#sociale div {
  margin-right: 42px;
}

#panel-boczny {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-column: 1 / 4;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/3;
  margin-top: 30px;
}

#panel-boczny .h2,
#panel-boczny a {
  display: inline;
  margin-top: 0px;
  margin-bottom: 0px;
  outline: none;
  cursor: pointer;
  z-index: 10;
  font-size: 17px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: black;
  text-decoration: none;
}

#panel-boczny #001-h2:focus ~ #001-p {
  display: block !important;
}

#panel-boczny #boks004 {
  padding-top: 20px;
  margin-bottom: 20px;
}

#panel-boczny #boks004 p {
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 19px;
}

#panel-boczny #boks004 p strong {
  font-size: 19px;
}

#panel-boczny #boks004 p a {
  font-size: 19px;
}


#stopka {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / 2;
  -ms-grid-row: 5;
  -ms-grid-row-span: 1;
  grid-row: 5/6;
  margin-left: 35px;
  -ms-flex-item-align: end;
      -ms-grid-row-align: end;
      align-self: end;
  border-top: 1px solid gray;
  margin-top: 15px;
}

#stopka h4 {
  font-size: 11px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 0px;
}

.glownyImg {
  -webkit-transition: all .1s linear;
  transition: all .1s linear;
  position: absolute;
}

#glowny {
  position: relative;
  -ms-grid-column: 4;
  -ms-grid-column-span: 2;
  grid-column: 4 / 6;
  -ms-grid-row: 1;
  -ms-grid-row-span: 5;
  grid-row: 1/6;
  max-height: 75vh;
  min-height: 75vh;
  max-width: 75vh;
  min-width: 75vh;
}

.glownyImgBackground {
  min-width: 100%;
  min-height: 100%;
  background-color: black;
}

.glownyImgBackground_ {
  min-width: 100%;
  min-height: 100%;
}

.glownyImgBackground_ img {
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
  -webkit-transition: all .9s ease;
  transition: all .9s ease;
}



@media screen and (max-width: 1400px) {
  #glowny {
    -ms-grid-column: 4;
    -ms-grid-column-span: 2;
    grid-column: 4 / 6;
    -ms-grid-row: 1;
    -ms-grid-row-span: 5;
    grid-row: 1/6;
    max-height: 65vh;
    min-height: 65vh;
    max-width: 65vh;
    min-width: 65vh;
  }
  .glownyImgBackground_ {
    width: 100%;
    height: 100%;
  }
  .content {
    margin-left: 5vw;
    margin-right: 2vw;
  }
}

@media screen and (max-width: 1200px) {
  #glowny {
    -ms-grid-column: 4;
    -ms-grid-column-span: 2;
    grid-column: 4 / 6;
    -ms-grid-row: 1;
    -ms-grid-row-span: 5;
    grid-row: 1/6;
    max-height: 55vh;
    min-height: 55vh;
    max-width: 55vh;
    min-width: 55vh;
  }
  .glownyImgBackground_ {
    min-width: 100%;
    min-height: 100%;
  }
}

@media screen and (max-width: 1100px) {
  #glowny {
    -ms-grid-column: 6;
    grid-column: 6 / 6;
    -ms-grid-row: 1;
    -ms-grid-row-span: 5;
    grid-row: 1/6;
    max-height: 55vh;
    min-height: 55vh;
    max-width: 55vh;
    min-width: 55vh;
  }
  .glownyImgBackground_ {
    min-width: 100%;
    min-height: 100%;
  }
  .content {
    margin-left: 1vw;
    margin-right: 2vw;
  }
}

.maska01 {
  -webkit-clip-path: polygon(100% 71%, 0 40%, 34% 100%);
          clip-path: polygon(100% 71%, 0 40%, 34% 100%);
  -webkit-transition: all .9s ease;
  transition: all .9s ease;
}

.maska01_ {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 71%, 34% 100%, 0 40%);
          clip-path: polygon(0 0, 100% 0, 100% 71%, 34% 100%, 0 40%);
  -webkit-transition: all .9s ease;
  transition: all .9s ease;
}

.maska02 {
  -webkit-clip-path: polygon(100% 37%, 0 70%, 55% 100%);
          clip-path: polygon(100% 37%, 0 70%, 55% 100%);
  -webkit-transition: all .9s ease;
  transition: all .9s ease;
}

.maska02_ {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 37%, 55% 100%, 0 70%);
          clip-path: polygon(0 0, 100% 0, 100% 37%, 55% 100%, 0 70%);
  -webkit-transition: all .9s ease;
  transition: all .9s ease;
}

.maska03 {
  -webkit-clip-path: polygon(100% 14%, 0 86%, 74% 100%);
          clip-path: polygon(100% 14%, 0 86%, 74% 100%);
  -webkit-transition: all .9s ease;
  transition: all .9s ease;
}

.maska03_ {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 14%, 74% 100%, 0 86%);
          clip-path: polygon(0 0, 100% 0, 100% 14%, 74% 100%, 0 86%);
  -webkit-transition: all .9s ease;
  transition: all .9s ease;
}

.maska04 {
  -webkit-clip-path: polygon(100% 20%, 0 55%, 75% 100%);
          clip-path: polygon(100% 20%, 0 55%, 75% 100%);
  -webkit-transition: all .9s ease;
  transition: all .9s ease;
}

.maska04_ {
  -webkit-clip-path: polygon(100% 0, 100% 20%, 75% 100%, 0 55%, 0 0);
          clip-path: polygon(100% 0, 100% 20%, 75% 100%, 0 55%, 0 0);
  -webkit-transition: all .9s ease;
  transition: all .9s ease;
}

@media screen and (max-width: 670px) {
  body {
    position: relative;
    height: auto;
    min-height: auto;
    max-width: 100vw;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
  }
  html {
    overflow-x: hidden;
  }
  .content {
    margin-left: 5px;
    margin-right: 5px;
  }
  .zawartosc {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto auto;
        grid-template-rows: auto auto auto auto auto;
    max-width: 100wv;
  }
  #logo {
    -ms-grid-column: 1;
    grid-column: 1 / 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/2;
    margin-left: 10vw;
  }
 
  #glowny {
    -ms-grid-column: 1;
    grid-column: 1 / 1;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2/3;
    max-height: 115vw;
    min-height: 115vw;
    max-width: 115vw;
    min-width: 115vw;
    margin-left: -10vw;
    margin-top: 45px;
  }
  .glownyImgBackground_ {
    min-width: 100%;
    min-height: 100%;
  }
  #panel-boczny {
    -ms-grid-column: 1;
    grid-column: 1 / 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-row: 3 / 4;
    max-width: 97vw;
  }
  #sociale {
    -ms-grid-column: 1;
    grid-column: 1 / 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1 / 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    max-width: 46vw;
    justify-self: end;
    max-height: 99px;
  }

  #sociale #ikony {
    margin-left: 5px;
    margin-right: 0;
    padding-right: 10vw;
  }

  #sociale #ikony div {
    margin-top: 5px;
    max-width: 5vw;
  }

  #sociale #ikony div img {
    max-width: 5vw;
    max-height: 5vw;
  }

  .gorna-belka {
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 10px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 200;
    padding: 10px;
  }

  .belka-dol {
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    font-size: 28px;
    font-weight: 800;
    color: gray;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-style: normal;
    padding: 10px;
    max-width: 95vw;
  }

  .belka-dol li {
    margin-right: 6px;
    margin-left: 6px;
  }

  #stopka {
    margin-top: 45px;
    max-width: 80vw;
  }

  #stopka h4 {
    font-size: 11px;
  }

  #boks005 p {
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 16px;
  }

  #boks005 p strong {
    font-size: 16px;
  }

  .arrow {
    width: 22px;
    height: 22px;
  }
}

/*Framework HRP----------------------------------*/
.separator10 {
  display: block;
  height: 10px;
}

.separator50 {
  display: block;
  height: 50px;
}

/*Nagłówki-------------------------------------------------------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  color: black;
}

h1 {
  font-size: 30px;
  font-weight: 600;
}

h2 {
  font-size: 25px;
  font-weight: 700;
}

h3 {
  font-size: 20px;
  font-weight: 700;
}

h4 {
  font-size: 15px;
  font-weight: 400;
}

h5 {
  font-size: 10px;
  font-weight: 400;
}

h6 {
  font-size: 8px;
  font-weight: 300;
}

/*Akapit-------------------------------------------------------------------------------------------*/
p {
  font-family: 'Montserrat', sans-serif;
  color: #333;
  font-size: 13px;
}

/*Linki-------------------------------------------------------------------------------------------*/
a {
  font-family: 'Montserrat', sans-serif;
  color: black;
  font-size: 13px;
}

/*Formatowanie tekstu-------------------------------------------------------------------------------------------*/
strong {
  font-family: 'Montserrat', sans-serif;
  color: black;
  font-size: 13px;
}

.obramowanie {
  border: 1px solid gray;
}

.tekst-do-lewej {
  text-align: left;
}

.tekst-do-srodka {
  text-align: center;
}

.tekst-do-prawej {
  text-align: right;
}

/*Układ-------------------------------------------------------------------------------------------*/
.poziomo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.gora {
  -ms-flex-item-align: start;
      align-self: flex-start;
}

.srodek {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.dol {
  -ms-flex-item-align: end;
      align-self: flex-end;
}

.kolumna-10 {
  width: 10%;
}

.kolumna-20 {
  width: 20%;
}

.kolumna-30 {
  width: 30%;
}

.kolumna-40 {
  width: 40%;
}

.kolumna-50 {
  width: 50%;
}

#particles-js {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -9999;
  -webkit-clip-path: polygon(0 0, 53% 0, 28% 100%, 0% 100%);
          clip-path: polygon(0 0, 53% 0, 28% 100%, 0% 100%);
}

@media screen and (max-width: 600px) {
  #particles-js {
    position: fixed;
    top: 0;
    left: 0;
    width: 250vw;
    height: 250vh;
    overflow: hidden;
    z-index: -9999;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 9%, 0 18%);
            clip-path: polygon(0 0, 100% 0, 100% 9%, 0 18%);
  }
}
/*# sourceMappingURL=style.css.map */


html {
  overflow: scroll;
  scroll-behavior: smooth;
}

.zawartosc {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1.5fr 1fr .5fr .5fr 1fr 1fr;
      grid-template-columns: 1.5fr 1fr .5fr .5fr 1fr 1fr;
  -ms-grid-rows: auto auto auto auto auto auto;
      grid-template-rows: auto auto auto auto auto auto;
}

#logo {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
}

#logo img {
  height: 85px;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

#maska {
  width: 18px;
  height: 75vh;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
}

#glowny {
  position: relative;
  -ms-grid-column: 2;
  -ms-grid-column-span: 5;
  grid-column: 2 / 7;
  -ms-grid-row: 1;
  -ms-grid-row-span: 4;
  grid-row: 1 / 5;
  display: block;
  -ms-flex-item-align: start;
      -ms-grid-row-align: start;
      align-self: start;
  max-width: 100%;
  width: 100%;
}

#glowny iframe {
  width: 100%;
  height: 75vh;
}

#glowny .projekty {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  height: 100%;
  width: 100%;
}

#glowny .projekty div {
  display: block;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  -ms-grid-column-align: center;
      justify-self: center;
  max-width: 280px;
  padding: 5px;
}

#glowny .projekty div img {
  max-width: 100%;
}

#panel-boczny {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / 2;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/3;
  margin-top: 10px;
}

#sociale {
  -ms-grid-row: 4;
  grid-row: 4/4;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
}

#stopka {
  width: 75%;
  -ms-grid-row: 5;
  grid-row: 5/5;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
}

#boks003 {
  margin-left: 34px;
  padding-right: 40px;
}

@media screen and (max-width: 1300px) {
  .projekty {
    -ms-grid-columns: 1fr 1fr 1fr !important;
        grid-template-columns: 1fr 1fr 1fr !important;
    gap: 5px;
  }
  .projekty div {
    max-width: 220px;
  }
}

@media screen and (max-width: 1000px) {
  .projekty {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr !important;
        grid-template-columns: 1fr 1fr !important;
    gap: 10px;
    height: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 670px) {
  .content {
    margin: 0px;
  }
  #maska {
    display: none;
  }
  .zawartosc {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .projekty {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #logo {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    margin-left: 0;
    margin-right: 0;
  }
  #glowny {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    max-height: -webkit-max-content;
    max-height: -moz-max-content;
    max-height: max-content;
    max-width: 100vw;
    min-width: 100vw;
    width: 100vw;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 0px;
  }
  #stopka {
    display: inline !important;
  }
  #sociale {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-left: 0;
    margin-top: 15px;
    margin-bottom: 25px;
  }
}
/*# sourceMappingURL=projekty.css.map */

html {
  overflow: scroll;
  scroll-behavior: smooth;
}

#glowny {
  position: relative;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  max-height: fit-content;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  width: 100%;
  height: 100%;
}

.img-background {
  position: absolute;
  top: 0;
  min-width: 70vh;
  min-height: 70vh;
  width: 100%;
  height: 100%;
}

.img-background img {
  position: absolute;
  top: 0;
  width: 100%;
}

.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  justify-items: center;
  min-height: 100vh;
}

.zawartosc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  max-width: 1200px;
}

#logo,
#sociale,
#stopka,
#_001 {
  -ms-flex-item-align: start;
      -ms-grid-row-align: start;
      align-self: start;
  margin: 0;
}

#logo {
  margin-left: -21px;
  margin-bottom: 0px;
}



.opisProjektu {
  padding-right: 50px;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.opisProjektu h3 {
  margin-top: 0;
}

.galeriaProjektu {
  -ms-grid-column-align: center;
      justify-self: center;
  justify-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.galeriaProjektu div {
  max-width: 50%;
  float: left;
  -ms-grid-column-align: center;
      justify-self: center;
  justify-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.galeriaProjektu img {
  max-width: 80%;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  -ms-grid-column-align: center;
      justify-self: center;
}

.gal2 {
  text-align: left;
}

.gal1 {
  text-align: right;
}

.gal1 img {
  padding-right: 5px;
}

#stopka h4 {
 margin-top: 50px;
}

.h2 h2 {
  margin: 0;
}

.h2 img {
  display: inline;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 22px;
  margin-top: 20px;
}

@media screen and (max-width: 1100px) {
  .content {
    margin-left: 4vw;
    margin-right: 2vw;
  }
  #logo {
    margin-left: -17px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1400px) {
  .zawartosc {
    max-width: 1200px;
    max-height: 900px;
  }
}

@media screen and (max-width: 700px) {
  #glowny {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  #glowny .galeriaProjektu {
    margin-top: 30px;
  }
}

@media screen and (max-width: 670px) {
  .opisProjektu {
    padding-right: 30px;
    padding-left: 20px;
  }
  #logo {
    margin-left: 0px;
    margin-bottom: 10px;
    margin-top: 20px;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  #_001 {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  #sociale #ikony {
    margin-left: -30px;
    margin-right: 0;
    padding-right: 0;
    margin-bottom: 15px;
  }
  #stopka {
    margin-left: -30px;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    text-align: center;
    max-width: 95vw;
  }
  #stopka h4 {
    margin-left: -30px;
  }
}

#galeria {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transition: all .5 ease-in-out;
  transition: all .5 ease-in-out;
}

#galeria #galeriaWraper {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#galeria #galeriaWraper #zamknij {
  display: block;
  font-family: 'PT Sans';
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

#galeria #galeriaWraper #nawigacja {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

#galeria #galeriaWraper #nawigacja span {
  font-family: 'PT Sans';
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
  margin-top: -3px;
  margin-left: 3px;
  margin-right: 3px;
}

#galeria #galeriaWraper #nawigacja span:hover {
  color: grey;
}

#galeria #galeriaWraper .imgBoks {
  width: 55vw;
  height: calc(55vw * 0.66);
  background-color: #fff;
  padding: 30px;
}

#galeria #galeriaWraper .imgBoks #slajd {
  width: 100%;
  height: 100%;
}

/* #galeria #galeriaWraper .imgBoks .s1 {
  background-image: url(../portfolio/projekty/img/1.jpg);
}

#galeria #galeriaWraper .imgBoks .s2 {
  background-image: url(../portfolio/projekty/img/2.jpg);
}

#galeria #galeriaWraper .imgBoks .s3 {
  background-image: url(../portfolio/projekty/img/3.jpg);
}

#galeria #galeriaWraper .imgBoks .s4 {
  background-image: url(../portfolio/projekty/img/4.jpg);
}

#galeria #galeriaWraper .imgBoks .s5 {
  background-image: url(../portfolio/projekty/img/5.jpg);
}

#galeria #galeriaWraper .imgBoks .s6 {
  background-image: url(../portfolio/projekty/img/6.jpg);
} */

#galeria #galeriaWraper .imgBoks .slajd {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.ukrytaGaleria {
  display: none;
}



/*-------------------------------------------------sort------------------------ */

.animation-holder {
  position: relative;
  width: max-content;
  min-height: 60vh;
  margin: 0 auto;
}

.animation-holder-img {
  position: absolute;
  top: 0;
  max-width: 100%;
  z-index: -999;
}

#tsparticles {
  z-index: -999;
}

#tsparticles canvas {
  clip-path: polygon(0 0, 45% 0, 20% 100%, 0% 100%);
}

@media screen and (max-width: 1000px) {
  .App {
    display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      max-width: 100vw;
      height: 100%;
      overflow-y: scroll;
  }

  .mainbox {
    height: 100%;
    flex-direction: column-reverse;
  }

  .img-background {
    position: absolute;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
  }
}